@import "../../variables";
$bg: rgba(0, 0, 0, 0.2);

.mining-modal {
  overflow: hidden;
  max-width: 90vw;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
  border: none;
  display: flex;
  flex-direction: column;
  color: $color-white-1;

  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-white-1;
      font-family: Cinzel, sans-serif;

      .body-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        overflow: hidden;
        font-size: 16px;
        margin: 10px;

        .character-image-container {
          img {
            width: 130px;
            height: 130px;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
              -webkit-filter: blur(2px);
              filter: blur(2px);
            }
          }
        }

        .place-holder-char-img {
          border-style: inset;
          width: 130px;
          height: 130px;
          border-radius: 8px;
          border-width: 0.5px;
        }

        .resource-counting {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .resource-button-group {
          display: flex;
          justify-content: center;
        }
      }

      .ant-typography {
        font-size: medium;
        font-family: Cinzel, sans-serif;
        color: $color-white-1;
      }
    }
  }

}

.confirmed-modal {
  overflow: hidden;
    max-width: 90vw;
    border-radius: 10px;
    background: rgba(0, 0, 0, 1);
    border: none;
    display: flex;
    flex-direction: column;
    color: $color-white-1;
  .ant-modal-content {
    background: none;

    .ant-modal-body {
      background: none;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ant-typography {
        font-size: medium;
        font-family: Cinzel, sans-serif;
        color: $color-white-1;
      }

      .resource-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .confirmed-button-container {
        display: flex;
        justify-content: flex-end;
      }
    }
    
  }
}