@import "../../variables";

.character-choose-modal {
  box-sizing: border-box;
  border-radius: 10px;
  background: rgba(0, 0, 0, 1);
  border: none;
  width: 110px;
  max-height: 500px;
  color: $color-white-1;
  position: fixed;
  right: 4%;
  padding: 5px;
  font-family: Cinzel, sans-serif;
  overflow: auto;
  .body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .character-img {
      cursor: pointer;
      width: 90%;
      height: 20%;
      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }
      .isNotLock {
        opacity: 0.5;
      }
      .canNotLearn {
        opacity: 0.5;
      }
      .isNFT {
        border-style: solid;
        border-color: red;
        border-radius: 8px;
      }
    }
    .character-img:hover {
      .tooltip-char-info {
        visibility: visible;
      }
    }
    overflow: hidden;
    margin: 10px;
  }
  
}

.character-choose-modal::-webkit-scrollbar {
  display: none;
}

.character-info-container {
  width: 200px;
  .character-name {
    font-weight: bold;
  }
  .red-highlight {
    color: red;
  }
}